// config.js
module.exports = {
    github: {
        username: 'mipmip', // Your GitHub org/user name. (Required)
        sortBy: 'stars', // stars | updated
        limit: 30, // How many projects to display.
        exclude: {
            forks: true, // Forked projects will not be displayed if set to true.
            projects: ['laravel-ecommerce'] // These projects will not be displayed. example: ['my-project1', 'my-project2']
        }
    },
    social: {
        linkedin: 'pimsnel',
        twitter: 'mipselaer',
        facebook: '',
        dribbble: '',
        behance: '',
        medium: '',
        devto: '',
        website: 'https://pimsnel.com',
        phone: '',
        email: ''
    },
    skills: [
        'JavaScript',
        'Go',
        'Ruby',
        'C',
        'Python',
        'Bash',
        'Objective-C',
        'Terraform',
        'Crystal',
        'AWS',
        'PostgreSQl',
        'NixOS',
        'Hugo',
        'Gnome',
        'Docker',
    ],
    experiences: [
        {
            company: 'Killerberg',
            position: 'Owner',
            from: 'May 2021',
            to: 'Present'
        },
        {
            company: 'PoppyGo',
            position: 'Owner/ Main Architect',
            from: 'January 2020',
            to: 'Present'
        },
        {
            company: 'Lingewoud',
            position: 'CEO',
            from: 'March 2000',
            to: 'April 2021'
        },
    ],
    education: [
        {
            institution: 'Utrecht University',
            degree: '',
            from: '1999',
            to: '2000'
        },
    ],
    /*
    blog: {
        // Display blog posts from your medium or dev.to account. (Optional)
        source: 'dev.to', // medium | dev.to
        username: 'arifszn',
        limit: 3 // How many posts to display. Max is 10.
    },
    */
    googleAnalytics: {
        // GA3 tracking id/GA4 tag id UA-XXXXXXXXX-X | G-XXXXXXXXXX
        id: '' // Please remove this and use your own tag id or keep it empty
    },
    hotjar: {
        id: '', //  Please remove this and use your own id or keep it empty
        snippetVersion : 6
    },
    themeConfig: {
        default: 'black',

        // Hides the switch in the navbar
        // Useful if you want to support a single color mode
        disableSwitch: true,

        // Should we use the prefers-color-scheme media-query,
        // using user system preferences, instead of the hardcoded default
        respectPrefersColorScheme: true,

        // Available themes. To remove any theme, exclude from here.
        themes: [
            'light',
            'dark',
            'cupcake',
            'bumblebee',
            'emerald',
            'corporate',
            'synthwave',
            'retro',
            'cyberpunk',
            'valentine',
            'halloween',
            'garden',
            'forest',
            'aqua',
            'lofi',
            'pastel',
            'fantasy',
            'wireframe',
            'black',
            'luxury',
            'dracula'
        ]
    }
}
